import React from "react";

//Css
import "./assets/vendor/switcher/switcher.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";

import "./assets/css/style.css";
import Index from "./pages/Index";

function App() {
  return (
    <>
      <Index />
    </>
  );
}

export default App;
