import { useState } from "react";
import Swal from "sweetalert2";
import PageTitle from "../elements/PageTitle";
import { Link } from "react-router-dom";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    emailTo: "info@performancehub.com.au",
  });
  const [sent, setSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://gianfrancoc-deno-mailer.deno.dev/", formData)
      .then((res) => {
        setSent(true);
        Swal.fire({
          title: "Message Sent",
          text: "We will get back to you soon",
          icon: "success",
          confirmButtonText: "Ok",
        });
        console.log(res);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong. Please try again later",
          icon: "error",
          confirmButtonText: "Ok",
        });
        console.log(err);
      });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Contact Us" parentTitle="Home" />

        <section className="content-inner-2 z-index-none">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-xl-5 m-sm-b30 m-xl-b0">
                <div className="contact-box">
                  <h3 className="contact-title">Contact Information</h3>
                  <p className="contact-text">
                    Fill up the form and our Team will get back to you within 24
                    hours.
                  </p>
                  <div className="widget widget_getintuch ">
                    <ul>
                      <li>
                        <i className="fa-solid fa-location-dot"></i>
                        <p>169 Rosamond, Rd. Maribyrnong. Vic</p>
                      </li>
                      <li>
                        <i className="fa-solid fa-phone"></i>
                        <p>+61-423-738-161</p>
                      </li>
                      <li>
                        <i className="fa-solid fa-phone"></i>
                        <p>+61-478-940-397</p>
                      </li>
                      <li>
                        <i className="fa-solid fa-envelope"></i>
                        <p>info@performancehub.com.au</p>
                      </li>
                    </ul>
                  </div>
                  <h6 className="m-b15 text-white">Our Socials</h6>
                  <div className="dz-social-icon style-1 dark">
                    <ul>
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.instagram.com/performancehub_australia/?hl=es"
                          rel="noreferrer"
                        >
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>{" "}
                    </ul>
                  </div>
                  <svg
                    width="250"
                    height="70"
                    viewBox="0 0 250 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 38L250 0L210 70L0 38Z"
                      fill="url(#paint0_linear_306_1296)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_306_1296"
                        x1="118.877"
                        y1="35.552"
                        x2="250.365"
                        y2="35.552"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="1" stopColor="var(--primary)" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="col-md-6 col-xl-7">
                <form
                  className="dz-form dzForm style-1"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    className="form-control"
                    name="dzToDo"
                    value="Contact"
                  />
                  <div className="dzFormMsg"></div>

                  {sent ? (
                    <div className="alert alert-success" role="alert">
                      Message sent. We will get back to you soon. Thank you!
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-group input-line">
                          <input
                            id="firstName"
                            onChange={handleInputChange}
                            value={formData.firstName}
                            required
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-group input-line">
                          <input
                            id="lastName"
                            onChange={handleInputChange}
                            value={formData.lastName}
                            required
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="input-group input-line">
                          <input
                            id="email"
                            onChange={handleInputChange}
                            value={formData.email}
                            required
                            type="text"
                            className="form-control"
                            placeholder="Your Email Address"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="input-group input-line">
                          <input
                            id="phone"
                            onChange={handleInputChange}
                            value={formData.phone}
                            required
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-group input-line m-b30">
                          <textarea
                            id="message"
                            onChange={handleInputChange}
                            value={formData.message}
                            rows="5"
                            required
                            className="form-control"
                            placeholder="Message..."
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="btn btn-primary btn-lg btn-skew"
                        >
                          <span>Send Message</span>
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
