import React from 'react';
import {Link} from 'react-router-dom';
import { IMAGES } from '../constants/theme';
import { HashLink } from 'react-router-hash-link';


const logoBlog = [
    {logo: IMAGES.logoHorizontal},
    {logo: IMAGES.logoHorizontal},
    {logo: IMAGES.logoHorizontal}
];

const MainBanner = ({isOpenModal}) => {
    return (
        <>
            <div className="banner-inner" style={{backgroundImage: "url("+ IMAGES.SliderBg1 +")"}}>
                <h2 className="data-text">
                    <span>P</span>
                    <span>O</span>
                    <span>T</span>
                    <span>E</span>
                    <span>N</span>
                    <span>T</span>
                    <span>I</span>
                    <span>A</span>
                    <span>L</span>
                </h2>
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-lg-6 col-md-7 col-sm-8">
                            <div className="banner-content">
                                <div className="top-content">
                                    <h1 className="title" >Perform at your best with<span className="text-primary"> Performance Hub</span></h1>
                                    <p >
                                    At Performance Hub, we empower young athletes by offering training programs that enhance speed, strength, skills, and incorporate the latest in sports neuroscience.
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <HashLink to='#about-us'  className="btn btn-skew btn-lg btn-primary shadow-primary"><span>Get Started</span></HashLink>
                                        <div className="video-bx4">
                                            <Link to={"#"} className="video-btn style-1 popup-youtube" 
                                                onClick={()=> isOpenModal(true)} >
                                                <i className="fa fa-play"/>{" "}
                                                <span className="text">Play Video</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <h4 className="partner-title" data-wow-delay="0.8s">Our Partners</h4>
                                    <div className="row">
                                        {logoBlog.map((data,ind)=>(
                                            <div className="col-4" key={ind}>
                                                <div className="clients-logo">
                                                    <img src={data.logo} alt="" />
                                                </div>
                                            </div>	
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-5 col-sm-4">
                            <div className="banner-media media1 anm wow fadeInRight" data-wow-delay="1s" data-speed-x="-2" data-speed-scale="-1">
                                <img src={IMAGES.logo} className="main-img" alt=""  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default MainBanner;