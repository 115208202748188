import React from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { IMAGES } from "../constants/theme";

const FitnessGoal = ({ isOpenModal }) => {
  return (
    <>
      <div className="col-lg-6 about-content m-b30">
        <div className="section-head m-0">
          <span className="sub-title">ABOUT US</span>
          <h2 className="title">
            Unlock Your <span>Full</span> Potential
          </h2>
          <p className="m-0">
            We are the number #1 neuroscience based football program in
            Australia. Elevate your speed, strength, skills and most importanly
            neuroscience
          </p>
        </div>
        <div className="" data-wow-delay="0.8s">
          <Tab.Container defaultActiveKey={"Mission"}>
            <Nav as="ul" className="nav nav-tabs style-1 m-b20 m-t30">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link className="nav-link" eventKey={"Mission"}>
                  <span>Our Mission</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link className="nav-link" eventKey={"Vision"}>
                  <span>Our Vision</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content
              className="tab-content m-sm-b30 m-b40 p-r30"
              id="myTabContent"
            >
              <Tab.Pane eventKey={"Mission"}>
                <div className="content">
                  <p>
                    Our mission is to unlock the full potential of every athlete
                    through a science-based approach that combines neuroscience,
                    speed, skills, and strength training. We are committed to
                    setting a high standard of excellence in athletic
                    performance and development.{" "}
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={"Vision"}>
                <div className="content">
                  <p>
                    To redefine athletic potential through science-driven,
                    holistic training. By integrating neuroscience, speed,
                    skills, and strength, we empower athletes to achieve peak
                    performance.
                  </p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <div className="contact-us">
          <span className="icon" style={{
            marginTop:"5px"
          }}>
            <i className="fa-solid fa-phone"></i>
          </span>
          <div
            className="content"
           
          >
            <span>Call us for help</span>
            <h4
              className="number"
              style={{
                fontSize: "20px",
              }}
            >
              +61-423-738-161
            </h4>
            <h4
              className="number"
              style={{
                fontSize: "20px",
              }}
            >
              +61-478-940-397
            </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-6 m-b30">
        <div className="dz-media">
          <div className="image-box">
            <div className="video-bx1 h-auto w-auto overflow-visible">
              <img src={IMAGES.aboutus} alt="" />
              <div className="video-btn sm">
                <Link
                  to={"#"}
                  className="popup-youtube"
                  onClick={() => isOpenModal(true)}
                >
                  <i className="fa fa-play" />
                </Link>
              </div>
            </div>
            <div className="info-box">
              <span>
                <i className="flaticon-play text-primary"></i> High Quality
                Video
              </span>
            </div>
          </div>
          <div className="image-box">
            <img src={IMAGES.aboutus2} alt="" />
            <div className="info-box">
              <span>
                <i className="flaticon-athletics text-primary"></i> Proffesional
                Coach
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FitnessGoal;
