import React from "react";
import { useParams } from "react-router-dom";
import BlogSidebar from "../elements/BlogSidebar";
import PageTitle from "../elements/PageTitle";
import { pillars } from "../constants/data";

const BlogDetail = () => {
  const { id } = useParams();
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage={pillars[id].title} parentTitle="Pillars" to="#pillars"/>
        <section className="content-inner">
          <div className="container">
            <div className="row ">
              <div className="col-xl-8 col-lg-8">
                <div className="blog-single dz-card sidebar">
                  <div className="dz-media">
                    <img src={pillars[id].image1} alt={pillars[id].title2}  style={{
                    width: "770px",
                    height: "450px",
                    objectFit: "cover",
                  }} />
                  </div>
                  <div className="dz-info m-b30">
                    <h2 className="dz-title">
                      {pillars[id].title2}
                    </h2>

                    <div className="dz-content">
                      <p>
                        {pillars[id].text1}
                      </p>
                      
                      <blockquote className="wp-block-quote">
                        <p>
                        "{pillars[id].quote}".
                        </p>
                        <cite> {pillars[id].author}</cite>
                      </blockquote>
                      <p>
                        {pillars[id].text2}
                      </p>
                      <ul className="list-check-2 m-b30">
                        {pillars[id].bullets && pillars[id].bullets.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="dz-share-post">
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <BlogSidebar/>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogDetail;
