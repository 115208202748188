import React from "react";
import { Link } from "react-router-dom";

const NewsLetter = () => {

  return (
    <>
      <div className="inner-content wow fadeInUp" data-wow-delay="0.8s">
        <div className="row justify-content-between align-items-center">
          <div className="text-center text-lg-start col-xl-6 m-lg-b20">
            <h2 className="title">Contact Us</h2>
            <p>
                Our team is available to address any questions or challenges you may encounter. 
            </p>
          </div>
          <div className="text-center text-lg-end col-xl-6">
            <div className="dzSubscribe">
              <div className="dzSubscribeMsg"></div>
              <div className="form-group mb-0">
                <div className="input-group-addon">
                  <Link
                    name="submit"
                    type="submit"
                    className="btn btn-secondary btn-lg btn-skew"
                    to="/contact-us"
                  >
                    <span>Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
