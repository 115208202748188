import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { servicesData as boxWrapper } from '../constants/data';

const PerformanceIdeas = () => {
    const [hoverBox , setHoverBox] = useState(0);
    return (
        <>
            <div className="row">
                {boxWrapper.slice(0,4).map((item, index)=>(
                    <div className="col-xl-3 col-md-6 m-b30" key={index}>
                        <div className={`icon-bx-wraper style-1 box-hover ${ index === hoverBox ? 'active' : ''}`} 
                            onMouseEnter={()=>setHoverBox(index)}
                        >
                            <div className="icon-bx m-b30"> 
                                <span className="icon-cell">
                                    <img src={item.image} alt="" style={{
                                        width: '80px',
                                        height: '80px'
                                    }}/>
                                </span>
                            </div>
                            <div className="icon-content">
                                <h5 className="dz-title m-b10"><Link to={`/services-details/${index}`}>{item.title}</Link></h5>
                                <p className="m-b25" 
                                    style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >{item.description}</p>
                                <Link to={`/services-details/${index}`} className="btn btn-primary shadow-primary btn-skew"><span>Read More</span></Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div> 
        </>
    );
};

export default PerformanceIdeas;