import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../constants/theme";

const mediaBlog = [
  { images: IMAGES.gusBeri, name: " 🇦🇷 GUS BERI", title: "PERFORMANCE COACH", instagram: 'https://www.instagram.com/coach_gusberi?igsh=em16Zmk1NTN4ZXNk'},
  { images: IMAGES.claudioAltieri, name: " 🇻🇪 CLAUDIO ALTIERI", title: "PERFORMANCE COACH", instagram: 'https://www.instagram.com/claudioaltieri.performance?igsh=MWU5MDRwemE1aDNmdg=='},
  { images: IMAGES.marcosReati, name: " 🇮🇹 MARCO REATI", title: "PERFORMANCE COACH", instagram: 'https://www.instagram.com/marcoreati_performance?igsh=MTE3Y2k1MGtweXZvYQ=='},
  { images: IMAGES.lucasVaz, name: " 🇧🇷 LUCAS VAZ", title: "FOOTBALL SKILLS COACH", instagram: 'https://www.instagram.com/lucasvazfutebol?igsh=MWJ3ODluZ3c5M3Q1ZQ=='}
  // { images: IMAGES.jaydenTran, name: "JAYDEN TRAN", title: "PERFORMANCE COACH"},
];

const Team = () => {
  return (
    <>
      <div className="page-content bg-white" id="team">
        <section className="content-inner-3">
          <div className="container">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-9 col-lg-8 col-xl-7">
                  <div className="section-head side-line">
                    <h5
                      className="sub-title wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      Our Team
                    </h5>
                    <h2 className="title wow fadeInUp" data-wow-delay="0.4s">
                      Meet Our Expert Team
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              {mediaBlog.map((item, index) => (
                <div className="col-lg-4 col-sm-6 m-b30" key={index}>
                  <div className="dz-team style-1">
                    <div className="dz-media">
                      <Link to={item.instagram} target="_blank">
                        <img src={item.images} alt="" 
                          style={{
                            width: "100%",
                            height: "500px",
                            objectFit: "cover",
                          }}
                        />
                      </Link>
                      <ul className="team-social">
                        {/* <li>
                          <Link
                            target="_blank"
                            to="https://www.facebook.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_blank"
                            to="https://twitter.com/?lang=en"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            target="_blank"
                            to={item.instagram}
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="dz-content " style={{
                      paddingTop: "20px",
                    }}>
                      <h4 className="dz-name">{item.name}</h4>
                      <span className="dz-position">{item.title}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
    
      </div>
    </>
  );
};

export default Team;
