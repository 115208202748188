import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import ClientSlider from "../components/ClientSlider";
import CounterBlog from "../components/CounterBlog";
import FitnessGoal from "../components/FitnessGoal";
import Modal from "react-modal";

//Components
import MainBanner from "../components/MainBanner";
import { IMAGES } from "../constants/theme";
import NewsLetter from "../components/ContactSection";
import LatestSlider from "../elements/LatestSlider";
import Team from "./Team";
import PerformanceIdeas from "../components/PerformanceIdeas";
import PerfectTrainers from "../components/PerfectTrainers";

const Home = () => {
  const [isOpen1, setOpen1] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const location = useLocation().pathname;

  useEffect(() => {
    const body = document.querySelector("body");
    body.setAttribute("data-theme-color", "color_1");
    localStorage.setItem("theme", "color_1");
    localStorage.setItem("themeInd", 0);
  }, [location]);
  return (
    <>
      <div className="page-content bg-white">
        {/* HERO BANNER */}
        <div className="main-bnr-one">
          <MainBanner isOpenModal={setOpen1} />
        </div>

        {/* COUNTER */}
        <section className="counter-wrapper1">
          <div className="container">
            <div className="counter-inner bg-dark">
              <div className="row">
                <CounterBlog />
              </div>
              <svg
                className="triangle1"
                width="250"
                height="70"
                viewBox="0 0 250 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M250 32L0 70L40 0L250 32Z"
                  fill="url(#paint0_linear_58_264)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_58_264"
                    x1="131.123"
                    y1="34.448"
                    x2="-0.36495"
                    y2="34.448"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="1" stopColor="var(--primary-dark)" />
                    <stop offset="1" stopColor="var(--primary)" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                className="triangle2"
                width="250"
                height="71"
                viewBox="0 0 250 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 38.3735L250 0.373535L210 70.3735L0 38.3735Z"
                  fill="url(#paint0_linear_58_261)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_58_261"
                    x1="118.877"
                    y1="35.9255"
                    x2="250.365"
                    y2="35.9255"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="1" stopColor="var(--primary-dark)" />
                    <stop offset="1" stopColor="var(--primary)" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </section>
        {/* ABOUT US */}
        <section
          id="about-us"
          className="content-inner about-wrapper2"
          style={{
            backgroundImage: "url(" + IMAGES.BgImage3 + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row about-bx2 align-items-center">
              <FitnessGoal isOpenModal={setOpen2} />
            </div>
          </div>
        </section>

        {/* Our values */}
        <section
          className="content-inner "
          id="values"
          style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <PerfectTrainers />
            </div>
          </div>
        </section>

        {/* Pillars Section */}
        <section
          id="pillars"
          className="content-inner overflow-hidden"
          style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}
        >
          <LatestSlider />
        </section>

        {/* Our Team */}
        <section id="team">
          <Team />
        </section>

        {/* Services Section */}
        <section
          id="services"
          className="content-inner overflow-hidden"
          style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center m-b20">
              <div className="col-md-9 col-lg-8 col-xl-7">
                <div className="section-head side-line">
                  <h5 className="sub-title wow fadeInUp" data-wow-delay="0.2s">
                    Our Services
                  </h5>
                  <h2 className="title wow fadeInUp" data-wow-delay="0.4s">
                    <span>Performance </span>Driven Solution
                  </h2>
                </div>
              </div>
              <div
                className="col-md-3 text-md-end wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <Link to={"/services"} className="btn btn-primary btn-skew">
                  <span>View All</span>
                </Link>
              </div>
            </div>
            <PerformanceIdeas />
          </div>
        </section>

        {/* Testimonials Section */}
        {/* <section
          id="testimonials"
          className="content-inner-1 testimonial-wrapper1"
          data-text="FEEDBACK"
          style={{
            backgroundImage: "url(" + IMAGES.BgImage2 + ")",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h5 className="sub-title wow fadeInUp" data-wow-delay="0.2s">
                TESTIMONIAL
              </h5>
              <h2 className="title wow fadeInUp" data-wow-delay="0.4s">
                What Client Say’s
              </h2>
            </div>
            <ClientSlider />
          </div>
        </section> */}
        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
      {/* <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen1}
        url="https://firebasestorage.googleapis.com/v0/b/performancehubau.appspot.com/o/copy_5F34D275-07D1-45CD-8C5A-3B1A6E0E14E5.mp4?alt=media&token=b5b21ccb-4a01-4cd2-aeda-558a71c59eff"
        onClose={() => setOpen1(false)}
        ratio="9:16"
      />
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen2}
        url="https://firebasestorage.googleapis.com/v0/b/performancehubau.appspot.com/o/IMG_3465.mp4?alt=media&token=dc2c6092-477a-4fba-b5e6-e0f71f59d247"
        onClose={() => setOpen2(false)}
        ratio="9:16"
      /> */}
      <Modal
        isOpen={isOpen1}
        onRequestClose={() => setOpen1(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)", // Fondo opaco
            zIndex: 1000, // Asegura que el modal esté por delante de todo
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        <ReactPlayer
          url="https://firebasestorage.googleapis.com/v0/b/performancehubau.appspot.com/o/copy_5F34D275-07D1-45CD-8C5A-3B1A6E0E14E5.mp4?alt=media&token=b5b21ccb-4a01-4cd2-aeda-558a71c59eff"
          controls
          playing
          width={'500px'}
          height={'500px'}
        />
      </Modal>

      <Modal
        isOpen={isOpen2}
        onRequestClose={() => setOpen2(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)", // Fondo opaco
            zIndex: 1000, // Asegura que el modal esté por delante de todo
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        <ReactPlayer
          url="https://firebasestorage.googleapis.com/v0/b/performancehubau.appspot.com/o/IMG_3465.mp4?alt=media&token=dc2c6092-477a-4fba-b5e6-e0f71f59d247"
          onClose={() => setOpen2(false)}
          controls
          playing
          width={'auto'}
        />
      </Modal>
    </>
  );
};

export default Home;
